<template>
  <b-dropdown right ref="archive_dropdown" class="dropdown-menu-right hide_dropdown_caret default_style_dropdown" :no-caret="false">
  <div class="w-40 border font-semibold !border-[#757A8A] rounded-lg px-[0.55rem] py-[0.5rem] flex items-center justify-between" slot="button-content">
    <span class="font-poppins text-[0.875rem] leading-none text-[#757A8A]">{{ selected }}</span>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M1 1L5 5L9 1" stroke="#757A8A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <ul class="w-full text-[#3C4549] font-poppins py-1 text-[0.875rem] shadow-lg bg-white font-poppins rounded-b-lg rounded-tl-lg">
      <li :key="index" v-for="(item, index) in list" @click.prevent="select(item)" class="cursor-pointer leading-none group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex justify-between font-poppins items-center">
        <p>{{ item }}</p>
        <svg v-if="item === selected" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
          <path d="M10.5 0.625L4.3125 7.375L1.5 4.30682" stroke="#2560D7" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </li>
  </ul>
</b-dropdown>
</template>
<script>
export default {
  data() {
    return {
      list: [
          'Show Unarchived',
          'Show Archived'
      ],
      selected: ''
    }
  },
  computed: {
    isActiveWorkspace() {
      return this.selected === 'Show Archived'
    }
  },
  created () {
    this.selected = this.list[0]
  },
  methods: {
    select(item) {
      this.selected = item
      this.$refs.archive_dropdown.hide(true)
      this.$emit('click', this.isActiveWorkspace)
    }
  }
}
</script>
